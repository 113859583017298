import type { RouteRecordRaw } from 'vue-router'

import ROUTE_NAME from '@/router/routeName'

export const requestsRoutes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAME.REQUESTS.INDEX,
    path: '/requests',
    component: () => import('@/modules/requests/views/RequestsView.vue'),
    redirect: { name: ROUTE_NAME.REQUESTS.ABSENCES.INDEX },
    children: [
      {
        name: ROUTE_NAME.REQUESTS.ABSENCES.INDEX,
        path: 'absences',
        component: () =>
          import('@/modules/requests/views/absences/RequestsAbsencesView.vue'),
        children: [],
      },
    ],
  },
]
