const ROUTE_NAME = {
  REQUESTS: {
    INDEX: 'route_name.requests.index',
    ABSENCES: {
      INDEX: 'route_name.requests.absences.index',
      PENDING: 'route_name.requests.absences.pending',
      APPROVED: 'route_name.requests.absences.approved',
      REJECTED: 'route_name.requests.absences.rejected',
      CANCELLED: 'route_name.requests.absences.cancelled',
    },
  },
  ACCIDENTS: {
    ALL: 'route_name.accidents.all',
    CONCEPT: 'route_name.accidents.concept',
    DETAILS: {
      ACCIDENT: 'route_name.accidents.details.accident',
      ACTIVITY: 'route_name.accidents.details.activity',
      DOCUMENTS: 'route_name.accidents.details.documents',
      GENERAL: 'route_name.accidents.details.general',
      INDEX: 'route_name.accidents.details.index',
    },
    DONE: 'route_name.accidents.done',
    INDEX: 'route_name.accidents.index',
    OPEN: 'route_name.accidents.open',
    PROGRESS: 'route_name.accidents.progress',
    REPORT: 'route_name.accidents.report',
  },
  ANOMALIES: {
    DETAIL: {
      INDEX: 'route_name.anomalies.detail.index',
    },
    EXCESS: {
      INDEX: 'route_name.anomalies.excess.index',
    },
    INDEX: 'route_name.anomalies.index',
    SHORTAGES: {
      INDEX: 'route_name.anomalies.shortages.index',
    },
    TO_VALIDATE: {
      INDEX: 'route_name.anomalies.to_validate.index',
    },
  },
  AUTH: {
    AD_CALLBACK: 'route_name.authentication.ad_callback',
    LOGIN: 'route_name.authentication.login',
    LOGOUT: 'route_name.authentication.logout',
    MAINTENANCE: 'route_name.authentication.maintenance',
    NO_PERMISSION: 'route_name.authentication.no_permission',
  },
  BASE: 'route_name.base',
  CLAIMS: {
    CONCEPT: {
      INDEX: 'route_name.claims.concept.index',
    },
    CREATE: 'route_name.claims.create',
    DETAIL: {
      INDEX: 'route_name.claims.detail.index',
    },
    INDEX: 'route_name.claims.index',
  },
  COMPENSATIONS: {
    ADVANCES: {
      ADMIN: 'route_name.compensations.admin.advances',
      DETAIL: 'route_name.compensations.advances.detail',
      INDEX: 'route_name.compensations.advances.index',
      STAFF: 'route_name.compensations.staff.advances',
    },
    ADVANCES_CORRECT: 'route_name.compensations.advances_correct',
    ADVANCES_REQUIRED_ATTENTION: 'route_name.compensations.advances_required_attention',
    CORRECTIONS: {
      APPROVED: {
        DETAIL: 'route_name.compensations.corrections.approved.detail',
        INDEX: 'route_name.compensations.corrections.approved.index',
      },
      EXPORTED: {
        DETAIL: 'route_name.compensations.corrections.exported.detail',
        INDEX: 'route_name.compensations.corrections.exported.index',
      },
      IN_PROGRESS: {
        DETAIL: 'route_name.compensations.corrections.in_progress.detail',
        INDEX: 'route_name.compensations.corrections.in_progress.index',
      },
      INDEX: 'route_name.compensations.corrections.index',
      REJECTED: {
        DETAIL: 'route_name.compensations.corrections.rejected.detail',
        INDEX: 'route_name.compensations.corrections.rejected.index',
      },
      REQUESTED: {
        DETAIL: 'route_name.compensations.corrections.requested.detail',
        INDEX: 'route_name.compensations.corrections.requested.index',
      },
    },
    INDEX: 'route_name.compensations.index',
    PAYMENTS: {
      ADMIN: 'route_name.compensations.admin.payments',
      DETAIL: 'route_name.compensations.payments.detail',
      INDEX: 'route_name.compensations.payments.index',
      STAFF: 'route_name.compensations.staff.payments',
    },
  },
  CUSTOMERS: {
    CREATE: 'route_name.customer.create',
    DETAIL: {
      ABSENCES: {
        INDEX: 'route_name.customer.detail.absence.index',
      },
      CONTRACT: {
        INDEX: 'route_name.customer.detail.contract.index',
        SUBSCRIPTION: 'route_name.customer.detail.contract.subscription',
        TERMS_OF_SERVICE: 'route_name.customer.detail.contract.terms_of_service',
      },
      DOCUMENTS: {
        INDEX: 'route_name.customer.detail.documents.index',
      },
      INDEX: 'route_name.customer.detail.index',
      INFO: {
        ACTIVATION: 'route_name.customer.detail.info.activation',
        CANCELLATION: 'route_name.customer.detail.info.cancellation',
        FINANCIAL: 'route_name.customer.detail.info.financial',
        INDEX: 'route_name.customer.detail.info.index',
        LEAD: 'route_name.customer.detail.info.lead',
        PERSONAL: 'route_name.customer.detail.info.personal',
        REFERRAL: 'route_name.customer.detail.info.referral',
        REJECTION: 'route_name.customer.detail.info.rejection',
        WORK_STATION: 'route_name.customer.detail.info.work_station',
      },
      NOTIFICATIONS: {
        DAMAGE_CLAIMS: 'route_name.customer.detail.notification.damage_claims',
        INDEX: 'route_name.customer.detail.notification.index',
        WORK_ACCIDENTS: 'route_name.customer.detail.notification.work_accidents',
      },
      OVERVIEW: {
        DASHBOARD: 'route_name.customer.detail.overview.dashboard',
        INDEX: 'route_name.customer.detail.overview.index',
        LOGBOOKS: 'route_name.customer.detail.overview.logbook',
        TODOS: 'route_name.customer.detail.overview.todo',
      },
      PLANNING: {
        AVAILABILITIES: 'route_name.customer.detail.planning.availabilities',
        EMPLOYEES: 'route_name.customer.detail.planning.employees',
        INDEX: 'route_name.customer.detail.planning.index',
        WEEK_PLANNING: 'route_name.customer.detail.planning.week_planning',
        REPLACEMENTS: 'route_name.customer.detail.planning.replacements',
      },
      REFUSED_MATCHES: {
        INDEX: 'route_name.customer.detail.refused_matches.index',
      },
      SERVICE_CHECKS: {
        CHECKS: 'route_name.customer.detail.service_checks.checks',
        INDEX: 'route_name.customer.detail.service_checks.index',
        SHORTAGES: 'route_name.customer.detail.service_checks.shortages',
      },
      TO_DOS: {
        COMPLETED: 'route_name.customer.detail.to_dos.completed',
        INDEX: 'route_name.customer.detail.to_dos.index',
        OPEN: 'route_name.customer.detail.to_dos.open',
      },
    },
    INDEX: 'route_name.customer.index',
    PROSPECT: {
      INDEX: 'route_name.customer.prospect',
    },
  },
  DASHBOARD: 'route_name.dashboard',
  DEBTORS: {
    CUSTOMERS: {
      CASE_DETAIL: 'route_name.debtors.customer.case_detail',
      CLOSED: 'route_name.debtors.customer.closed',
      CUSTOMER_DETAIL: 'route_name.debtors.customer.customer_detail',
      INDEX: 'route_name.debtors.customer.index',
      NEW: 'route_name.debtors.customer.new',
      OPEN: 'route_name.debtors.customer.open',
    },
    EMPLOYEES: {
      DETAIL: 'route_name.debtors.customer.detail',
      INDEX: 'route_name.debtors.employees.index',
    },
    INDEX: 'route_name.debtors.index',
  },
  EMPLOYEES: {
    ACTIVE: 'route_name.employees.active',
    ALL: 'route_name.employees.all',
    APPLICATION: {
      INDEX: 'route_name.employees.application.index',
    },
    CANDIDATE: {
      INDEX: 'route_name.employees.candidate.index',
    },
    CANDIDATES: 'route_name.employees.candidates',
    CONCEPTS: 'route_name.employees.concepts',
    DETAIL: {
      ABSENCES: {
        ACCIDENTS: 'route_name.employees.detail.absences.accidents',
        COUNTERS: 'route_name.employees.detail.absences.counters',
        ILLNESS: 'route_name.employees.detail.absences.illness',
        INDEX: 'route_name.employees.detail.absences.index',
        LEAVE: 'route_name.employees.detail.absences.leave',
        OTHER: 'route_name.employees.detail.absences.other',
        PART_TIME: 'route_name.employees.detail.absences.part_time',
        PART_TIME_UPDATE: 'route_name.employees.detail.absences.part_time_update',
        PREGNANCY: 'route_name.employees.detail.absences.pregnancy',
      },
      COMPENSATION: {
        ADVANCE: 'route_name.employees.detail.compensation.advance-payment-rejection',
        ECONOMIC_UNEMPLOYMENT: 'route_name.employees.detail.compensation.economic_unemployment',
        INDEX: 'route_name.employees.detail.compensation.index',
        REFERRAL_PREMIUMS: 'route_name.employees.detail.compensation.referral_premiums',
        TAX_WITHHOLDING: 'route_name.employees.detail.compensation.tax_withholding',
        WAGE: 'route_name.employees.detail.compensation.wage',
        WAGE_BENEFITS: 'route_name.employees.detail.compensation.benefits',
      },
      CONTRACTS: {
        CONCEPT: 'route_name.employees.detail.contracts.concept',
        DETAIL: 'route_name.employees.detail.contracts.detail',
        DIMONA_DECLARATIONS_INDEX: 'route_name.employees.detail.contracts.dimona_declarations_index',
        INDEX: 'route_name.employees.detail.contracts.index',
        OVERVIEW: 'route_name.employees.detail.contracts.overview',
        TIMETABLE_CONCEPT: 'route_name.employees.detail.contracts.timetable_concept',
        TIMETABLE_CORRECTION_CONCEPT: 'route_name.employees.detail.contracts.timetable_correction_concept',
      },
      DOCUMENTS: {
        INDEX: 'route_name.employees.detail.documents.index',
      },
      INDEX: 'route_name.employees.detail.index',

      INFO: {
        APPLICATION: 'route_name.employees.detail.info.application',
        CANCELLATION: 'route_name.employees.detail.info.cancellation',
        FISCAL: 'route_name.employees.detail.info.fiscal',
        INDEX: 'route_name.employees.detail.info.index',
        LEAD: 'route_name.employees.detail.info.lead',
        PERSONAL: 'route_name.employees.detail.info.personal',
        PLUXEE_LOGIN: 'route_name.employees.detail.info.pluxee_login',
        PROFESSIONAL: 'route_name.employees.detail.info.professional',
        REJECTION: 'route_name.employees.detail.info.rejection',
        SUBSIDIES: 'route_name.employees.detail.info.subsidies',
      },
      NOTIFICATIONS: {
        DAMAGE_CLAIMS: 'route_name.employees.detail.notification.damage_claims',
        INDEX: 'route_name.employees.detail.notification.index',
        WORK_ACCIDENTS: 'route_name.employees.detail.notification.work_accidents',
      },
      OVERVIEW: {
        DASHBOARD: 'route_name.employees.detail.overview.dashboard',
        INDEX: 'route_name.employees.detail.overview.index',
        LOGBOOKS: 'route_name.employees.detail.overview.logbooks',
        TODOS: 'route_name.employees.detail.overview.todos',
      },
      PLANNING: {
        AVAILABILITIES: 'route_name.employees.detail.planning.availabilities',
        CALENDAR: 'route_name.employees.detail.planning.calendar',
        CUSTOMERS: 'route_name.employees.detail.planning.customer',
        INDEX: 'route_name.employees.detail.planning.index',
      },
      SERVICE_CHECKS: {
        CHECKS: {
          INDEX: 'route_name.employees.detail.service_checks.checks.index',
        },
        INDEX: 'route_name.employees.detail.service_checks.index',
        SHORTAGES: {
          INDEX: 'route_name.employees.detail.service_checks.shortages.index',
        },
        TREATABLE: {
          INDEX: 'route_name.employees.detail.service_checks.treatable.index',
        },
      },
      TO_DOS: {
        COMPLETED: 'route_name.employees.detail.to_dos.completed',
        INDEX: 'route_name.employees.detail.to_dos.index',
        OPEN: 'route_name.employees.detail.to_dos.open',
      },
    },
    DETAIL_OVERVIEW: 'route_name.employees.detail.overview',
    ENDING: 'route_name.employees.ending',
    INACTIVE: 'route_name.employees.inactive',
    INDEX: 'route_name.employees.index',
    NOT_RETAINED: 'route_name.employees.not.retained',
    QUARANTINE: 'route_name.employees.quarantine',
  },
  ERROR: 'route_name.error',
  GENERAL: 'route_name.general',
  GLOBAL_SEARCH: 'route_name.global_search',
  LOGBOOKS: {
    INDEX: 'route_name.logbook.index',
  },
  MATCHING_CENTER: {
    CUSTOMERS: 'route_name.matching_center.customer',
    EMPLOYEES: 'route_name.matching_center.employees',
    INDEX: 'route_name.matching_center.index',
    RESERVATIONS: 'route_name.matching_center.reservations',
    UNSATISFIED_CUSTOMERS: 'route_name.matching_center.unsatisfied_customers',
  },
  NEWS: {
    CONCEPTS: 'route_name.news.concepts',
    CREATE: 'route_name.news.create',
    DETAIL: 'route_name.news.detail',
    EDIT: 'route_name.news.edit',
    INDEX: 'route_name.news.index',
    PLANNED: 'route_name.news.planned',
  },
  RAPPORTS: {
    ACCIDENTS: 'route_name.rapports.accidents',
    ADVANCES: 'route_name.rapports.advances',
    APPLICATION_PREMIUM: 'route_name.rapports.application_premium',
    DAMAGE: 'route_name.rapports.damage',
    DEBITOR: 'route_name.rapports.debitor',
    FLEET_MANAGER: 'route_name.rapports.fleet_manager',
    PAYCHECKS: 'route_name.rapports.paychecks',
  },
  REFERRAL_PREMIUM: {
    ACHIEVED: 'route_name.application_premium.achieved',
    ACTIVE: 'route_name.application_premium.active',
    ALL: 'route_name.application_premium.all',
    CONCEPT: 'route_name.application_premium.concept',
    DENIED: 'route_name.application_premium.denied',
    DETAIL: 'route_name.application_premium.detail',
    INDEX: 'route_name.application_premium.index',
    PAID: 'route_name.application_premium.paid',
    TO_BE_PAID: 'route_name.application_premium.to_be_paid',
  },
  REMITTANCE: {
    DETAIL: 'route_name.remittance.detail',
    INDEX: 'route_name.remittance.index',
  },
  SETTINGS: {
    APPLICATION: {
      ABSENCE_CODES: 'route_name.settings.application.absence_codes',
      FAQ: 'route_name.settings.application.faq',
      INDEX: 'route_name.settings.application.index',
      INFO_BUBBLES: 'route_name.settings.application.info_bubbles',
      PLANNING_REMARKS: 'route_name.settings.application.planning_remarks',
      TERMS_OF_SERVICE: 'route_name.settings.application.terms_of_service',
      TRANSLATIONS: 'route_name.settings.application.translations',
    },
    BUSINESSES: {
      DEPARTMENT_DETAIL: 'route_name.settings.businesses.department.detail',
      DEPARTMENT_MERGE_DETAIL: 'route_name.settings.businesses.department_merge.detail',
      DEPARTMENT_MERGES: 'route_name.settings.businesses.department_merges',
      DEPARTMENTS: 'route_name.settings.businesses.departments',
      DISTRICT_DETAIL: 'route_name.settings.businesses.districts.detail',
      DISTRICT_DETAIL_NO_DM: 'route_name.settings.businesses.districts.detail.no_dm',
      DISTRICTS: 'route_name.settings.businesses.districts',
      INDEX: 'route_name.settings.businesses.index',
      OFFICE_DETAIL: 'route_name.settings.businesses.office.detail',
      OFFICES: 'route_name.settings.businesses.office',
      ORIGIN_COMPANIES: 'route_name.settings.businesses.origin_companies',
      ORIGIN_COMPANY_DETAIL: 'route_name.settings.businesses.origin_company.detail',
      PAYROLL_DETAIL: {
        EMPLOYEES: 'route_name.settings.businesses.payroll.detail.employees',
        INDEX: 'route_name.settings.businesses.payroll.detail.index',
        OFFICES: 'route_name.settings.businesses.payroll.detail.offices',
      },
      PAYROLL_MERGE_DETAIL: 'route_name.settings.businesses.payroll_merge.detail',
      PAYROLL_MERGES: 'route_name.settings.businesses.payroll_merges',
      PAYROLLS: 'route_name.settings.businesses.payroll_overview',
    },
    COMPENSATION: {
      ABSENCE_CODES: 'route_name.settings.compensation.absence_codes',
      ADVANCE_AMOUNT: 'route_name.settings.compensation.advance_amount',
      BAREMA: 'route_name.settings.compensation.barema',
      BENEFIT_CODES: 'route_name.settings.compensation.benefit_codes',
      HOLIDAYS: 'route_name.settings.compensation.holidays',
      INDEX: 'route_name.settings.compensation.index',
      PERIODS_ECONOMIC_UNEMPLOYMENT: 'route_name.settings.compensation.periods_economic_unemployment',
    },
    INDEX: 'route_name.settings.index',
    SERVICE_CHECKS: {
      INDEX: 'route_name.settings.service_checks.index',
      REIMBURSEMENT_VALUE: 'route_name.settings.service_checks.reimbursement',
    },
    SUBSCRIPTIONS: {
      CONFIGURATION: 'route_name.settings.subscriptions.configuration',
      CONFIGURATION_DETAIL: 'route_name.settings.subscriptions.configuration_detail',
      HOME_MAID: 'route_name.settings.subscriptions.home_main',
      INDEX: 'route_name.settings.subscriptions.index',
      TRIXXA: 'route_name.settings.subscriptions.trixxa',
    },
    SUBSIDY: {
      ALL: 'route_name.settings.subsidy.all',
      CREATE: 'route_name.settings.subsidy.create',
      DETAIL: 'route_name.settings.subsidy.detail',
      EDIT: 'route_name.settings.subsidy.edit',
      INDEX: 'route_name.settings.subsidy.index',
      SUBSIDIES: 'route_name.settings.subsidies',
    },
    SYNCHRONISATIONS: {
      CRON_JOB: {
        INDEX: 'route_name.settings.synchronisations.cron_job.index',
      },
      DIMONA: 'route_name.settings.synchronisations.dimona-declaration',
      EMMA: 'route_name.settings.synchronisations.emma',
      INDEX: 'route_name.settings.synchronisations.index',
      JOB: {
        ARCHIVED: 'route_name.settings.synchronisations.cron_job.archived',
        CURRENT: 'route_name.settings.synchronisations.cron_job.current',
        INDEX: 'route_name.settings.synchronisations.job.index',
      },
      PARTENA: {
        ERP_FILES: 'route_name.settings.synchronisations.partena.erp_files',
        ERP_FILES_DETAIL: 'route_name.settings.synchronisations.partena.erp_files_detail',
        INDEX: 'route_name.settings.synchronisations.partena.index',
        RECORDS: 'route_name.settings.synchronisations.partena.records',
        TIME_FILES: 'route_name.settings.synchronisations.partena.time_files',
        TIME_FILES_DETAIL: 'route_name.settings.synchronisations.partena.time_files_detail',
        VARIABLE_SCHEDULES: 'route_name.settings.synchronisations.partena.variable_schedules',
        WAGE_FILES: 'route_name.settings.synchronisations.partena.wage_files',
        WAGE_FILES_DETAIL: 'route_name.settings.synchronisations.partena.wage_files_detail',
        WARNINGS: 'route_name.settings.synchronisations.partena.warnings',
      },
      PARTENA_EXPORT_FILES: 'route_name.settings.synchronisations.export_files',
      PLUXEE: {
        INDEX: 'route_name.settings.synchronisations.pluxee-login-login.index',
        LOGINS: 'route_name.settings.synchronisations.pluxee-login-login.logins',
      },
      RSZ: {
        DECLARATIONS: {
          DETAIL: 'route_name.settings.synchronisations.rsz.declarations.detail',
          INDEX: 'route_name.settings.synchronisations.rsz.declarations.index',
        },
        INDEX: 'route_name.settings.synchronisations.rsz.index',
        NOTIFICATIONS: {
          DETAIL: 'route_name.settings.synchronisations.rsz.notification.detail',
          INDEX: 'route_name.settings.synchronisations.rsz.notification.index',
        },
        VALIDATION_BOOK: {
          INDEX: 'route_name.settings.synchronisations.rsz.validation_book.index',
        },
      },
    },
    USERS: {
      INDEX: 'route_name.settings.users.index',
      ROLES_AND_PERMISSIONS: 'route_name.settings.users.roles_and_permissions',
      STAFF: 'route_name.settings.users.users',
      STAFF_DETAIL: 'route_name.settings.users.users.detail',
    },
  },
  SUBSIDIES: {
    DETAIL: 'route_name.subsidies.detail',
    INDEX: 'route_name.subsidies.index',
  },
  TODO: {
    CLOSED: 'route_name.todo.closed',
    FOLLOW_UP: 'route_name.todo.follow_up',
    INDEX: 'route_name.todo.index',
    OPEN: 'route_name.todo.open',
  },
  LOGBOOK: {
    INDEX: 'route_name.logbook.index',
  },
}

export default ROUTE_NAME
